
.live-data-all {
  width: 100%;
  height: 100vh;
  background-color: #080b32;
  overflow: hidden;
  padding: 10px 40px;
  box-sizing: border-box;

  ::v-deep .el-scrollbar__wrap {
    min-width: 1412px;
    margin: 0 !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_title {
    font-weight: 800;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 40px;
  }

  &_content {
    // height: calc(100vh - 144px);
    box-sizing: border-box;
    display: flex;
    gap: 30px 20px;
    position: relative;
    flex-wrap: wrap;

    .content-operate {
      position: absolute;
      top: -60px;
      left: 0px;
    }

    .live-item {
      aspect-ratio: 352 / 529;
      box-sizing: border-box;
      padding-bottom: 16px;
      background-color: #0c0e3f;
      border-radius: 8px;
      width: calc((100% - 80px) / 5);
      flex-shrink: 0;
      flex-grow: 0;

      &_play {
        background-color: #ffffff27;
        height: calc(100% - 91px);
        border-radius: 8px;
        margin-bottom: 16px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        .play-status {
          position: absolute;
          top: 0;
          right: 0;
          padding: 2px 6px;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          z-index: 999;

          .iconfont {
            font-size: 12px;
            margin-right: 2px;
          }

          &.pending,
          &.pause {
            background-color: #4741ff99;
          }

          &.over {
            background-color: #ff414498;
          }

          &.no-aired,
          &.no-start,
          &.disconnect {
            background-color: #e2e2e299;
          }
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        ::v-deep .video-js {
          width: 100%;
          height: 100%;
        }
      }

      &_title {
        padding-inline: 10px;
        margin-bottom: 12px;
        height: 25px;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        line-height: 25px;
        white-space: nowrap; /* 防止文本换行 */
        overflow: hidden; /* 隐藏超出容器的部分 */
        text-overflow: ellipsis; /* 超出部分显示省略号 */
      }

      &_user {
        height: 30px;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        padding-inline: 10px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .user-info,
        .user-hot {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .user-info {
          width: 60%;

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
          }

          .user-info-name {
            width: calc(100% - 24px);
            white-space: nowrap; /* 防止文本换行 */
            overflow: hidden; /* 隐藏超出容器的部分 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }

        .user-hot {
          .iconfont {
            color: #19fdff;
          }
        }
      }
    }
  }

  &_pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep .el-pagination {
      background-color: transparent;

      button {
        background: transparent;
        color: #91a1d9;
      }

      .el-pager {
        li {
          background: transparent;
          color: #91a1d9;
          border-radius: 50%;
          min-width: 28px;

          & + li {
            margin-left: 10px;
          }

          &.active {
            color: #ffffff;
            background-color: #564ced;
          }
        }
      }

      .el-pagination__jump {
        color: #91a1d9;

        .el-input__inner {
          background: transparent;
          color: #91a1d9;
          border-color: #91a1d9;
        }
      }
    }
  }
}
